import * as client_hooks from '../../../src/app/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/(with-breadcrumbs)/about-us": [4,[2]],
		"/api/institutions-subscribe": [~13],
		"/api/subscribe": [~14],
		"/(with-breadcrumbs)/artist-wallet-authorization/[key]": [~6,[2]],
		"/(with-breadcrumbs)/artist-[id]-[slug]/[[page=page]]": [~5,[2]],
		"/(with-breadcrumbs)/artists": [7,[2]],
		"/artwork-[id]-[slug]/[[param1]]": [~15],
		"/(with-breadcrumbs)/artworks/classification-[classificationCategory]-[nameOfClassification]/[[page]]": [9,[2]],
		"/(with-breadcrumbs)/artworks/[[page]]": [8,[2]],
		"/auction-[id]": [~16],
		"/(with-breadcrumbs)/auctions": [10,[2]],
		"/(with-breadcrumbs)/contact-us": [~11,[2]],
		"/(with-breadcrumbs)/faq": [12,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';